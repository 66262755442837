import { html  } from 'lit';
import IVPLitElementBase from './lit-element-base.js';

export default class DlgSelectOperator extends IVPLitElementBase {

	static get tag() { return "select-operator-dialog" }

    static get properties() {
        return {            
            /*title:  { type: String },*/
        };
    }

    constructor() {
        super();

        this.dialog_id = "select_operator_dialog"
    }

    render() {
        return html`
        <!-- Modal -->
        <div class="modal fade" id="${this.dialog_id}" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered " role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Seleziona operatore</h5>
                        <button type="button" class="close" @click="${this.OnNo}" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Sleziona operatore per la pratica ${this.yard_name}</label>
                            <select class="form-control select-operator" >
                                ${this.operators.map((el)=>{
                                    return html`<option value="${el.id}">${el.login}</option>`
                                })}
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="${this.OnNo}">Annulla</button>
                        <button type="button" class="btn btn-primary" @click="${this.OnYes}">Salva</button>                            
                    </div>
                </div>
            </div>
        </div>
        `
    }
    
    firstUpdated(changedProps) {
        $(`#${this.dialog_id}`).modal({backdrop:'static',keyboard:false})
        if (this.operator_id != null)
            this.querySelector(".select-operator").value = this.operator_id
        else    
            this.querySelector(".select-operator").selectedIndex = -1
    }

    updated() {        
    }

    static Show(yard_name,operator_id,operators) {

        let el = new DlgSelectOperator()
        el.operators = operators
        el.yard_name = yard_name
        el.operator_id = operator_id

        el.promise = new Promise((resolve) => {
            el.promise_resolve = resolve
        })
        
        let vels = document.querySelectorAll(this.tag)
		if (vels != null)
			vels.forEach((el)=>{ el.remove() })

        document.body.append(el)
        return el.promise
    }

    Remove() {
        $('#select_operator_dialog').modal('hide')
        this.remove()
    }    

    OnYes() {
        let val = this.querySelector(".select-operator").value
        console.log(val)
        this.promise_resolve(val)
        this.Remove()
    }

    OnNo() {
        this.promise_resolve(null)
        this.Remove()
    }
    
}
DlgSelectOperator.RegisterElement()