import Auth from './auth.js'
import Api from './lib/api.js';
import DlgPrivacy from './lib/privacy.js'
import IVPLoader from './lib/loader.js'
import DlgSelectOperator from './lib/select-operator-dialog.js';
import DlgPopup from './lib/popup-dialog.js';


document.addEventListener("DOMContentLoaded",async()=>{

    
    IVPLoader.Show()

    if (await Auth.Init() == false)
        return

    const urlParams = new URLSearchParams(window.location.search);

    let show_only_to_approve = (urlParams.get("all") == null) && (Auth.user.profile != 'CUSTOMER')
    if (show_only_to_approve == true) {
        document.querySelector("#sidebar-menu .yards_to_approve").classList.add("active")
        document.querySelector(".navbar-header .breadcrumb").append($(`<li class="breadcrumb-item active">Pratiche da approvare</li>`)[0])
        document.querySelector(".card-title").innerHTML = "Pratiche da approvare"
    } else {
        document.querySelector("#sidebar-menu .yards").classList.add("active")
        document.querySelector(".navbar-header .breadcrumb").append($(`<li class="breadcrumb-item active">Pratiche</li>`)[0])
    }    

    if (Auth.user.privacy_accepted == false)
        await DlgPrivacy.EnsureAccepted()
        
    document.querySelector("#layout-wrapper").classList.remove("hidden")
        
    // Default Datatable
    let resp = await Api.GetYards()

    // Se deve mostrare solo quelle in approvazione le filtra
    if (show_only_to_approve == true)
        resp = resp.filter((row)=>{
            return row.status == 'APPROVATION'
        })

    let columns = [                        
        { title: 'Nome', data: 'name' },
        //{ title: 'Indirizzo', data: 'address' },
        { title: 'Città', data: 'city_province' },
        //{ title: 'Cap', data: 'zip' },
        { title: 'Importo', data: 'amount' },          
        { title: 'Contractor', data: 'contractor_name' }, 
        { title: 'Operatore', data: 'operator_name' },
        { title: 'Step', data: 'active_section' },
        { title: 'Stato', data: 'status' },
        { title: ' ', data: '' },
    ]

    let hide_columns = {}
    if (Auth.user.profile != "ADMIN")
        hide_columns = { visible: false, targets: [4] }

    let btb = $('#basic-datatable').DataTable({
      
        "pageLength": 50,
        "language": {
            "paginate": {
                "previous": "<i class='mdi mdi-chevron-left'>",
                "next": "<i class='mdi mdi-chevron-right'>"
            },
            processing:     "Elaborazione dati...",
            search:         "Ricerca&nbsp;:",
            lengthMenu:    "Visualizza _MENU_ record",
            info:           "Visualizzati da _START_ a _END_ di _TOTAL_ record",
            infoEmpty:      "Nessun record in archivio",
            infoFiltered:   "(trovati su _MAX_ in totale)",
            infoPostFix:    "",
            loadingRecords: "Caricamento dati...",
            zeroRecords:    "Nessun record",
            emptyTable:     "Archivio vuoto",
            sortAscending:  ": activer pour trier la colonne par ordre croissant",
            sortDescending: ": activer pour trier la colonne par ordre décroissant"
        },

        columns: columns,
        columnDefs: [   
            {
                "targets": 2,
                "render": function ( data, type, row, meta ) {
                    const formatter = new Intl.NumberFormat('it-IT', {
                        style: 'currency',
                        currency: 'EUR',
                    
                      });
                    return formatter.format(data)                    
                }
            },          
            {
                "targets": 5,
                "render": function ( data, type, row, meta ) {
                    if (data == 'TENANTS')
                        return "CONDOMINI"
                    if (data == 'ADVANCE')
                        return "ACCONTO"
                    return data                    
                }
            },            
            {
                "targets": 6,
                "render": function ( data, type, row, meta ) {
                    if (data == 'INPUT_DATA')
                        return "Caricamento dati"
                    else if (data == 'APPROVATION')
                        return `<b style="color:red">In approvazione</b>`
                    else if (data == 'APPROVED')
                        return `<b style="color:#029f00">Approvata</b>`

                    return ""                    
                }
            },
            {   
                targets: [-1],
                render: function (data, type, row) {
                    return `
                        <div class="d-flex flex-row">
                        <button type='button' class='ADMIN-ONLY btnOperator btn btn-outline-secondary btn-sm mr-1'>Operatore</button>
                        <button type='button' class='btnEdit btn btn-outline-secondary btn-sm '>Modifica</button>
                        </div>
                    `
                },                
                
            },            
            hide_columns
        ],
        data: resp,
        //dom: 'Bfrtip',
        buttons: [
            'excel','pdf'
        ]

    });
    btb.buttons().container()
    .appendTo($("#table-buttons"))
    
    let fnEdit = ()=> {
        $("#basic-datatable .btnEdit").off('click')
        $("#basic-datatable .btnEdit").click((e)=>{
            let tr = btb.row(e.target.closest('tr'))
            if (tr.data().active_section == "GENERALE")
                document.location.href = "yard.html?id="+tr.data().id
            else if (tr.data().active_section == "TENANTS")
                document.location.href = `yard_condomini.html?id=${tr.data().id}`
            else if (tr.data().active_section == "ADVANCE")
                document.location.href = `yard_acconto.html?id=${tr.data().id}`               
            else if (tr.data().active_section == "SAL")
                document.location.href = `yard_sal.html?id=${tr.data().id}`            
            console.log(tr.data())
        })
    }

    fnEdit()

    let fnOperator = ()=> {
        $("#basic-datatable .btnOperator").off('click')
        $("#basic-datatable .btnOperator").click(async (e)=>{
            try {
                let tr = btb.row(e.target.closest('tr'))
                IVPLoader.Show()
                let oprs = await Api.GetOperators()
                IVPLoader.Remove()
                let chosen_operator_id = await DlgSelectOperator.Show(tr.data().name, tr.data().operator_id, oprs)
                if (chosen_operator_id != null) {
                    IVPLoader.Show()
                    await Api.SetYardOperator(tr.data().id,chosen_operator_id)
                    let resp = await Api.GetYards()
                    btb.clear();
                    btb.rows.add(resp);                    
                    btb.draw();
                    IVPLoader.Remove()                    
                }
            }
            catch (Ex) {
                IVPLoader.Remove()                    
                console.error(Ex)
                DlgPopup.Show("Errore","Ooops! Qualcosa è andato storto. Riprovare.")
            }
        })
    }    
    fnOperator()
    
    btb.on( 'draw init.dt', fnEdit)
    btb.on( 'draw init.dt', fnOperator)

    document.querySelector(".page-content").classList.remove("hidden")

    IVPLoader.Remove()
})