import { html  } from 'lit';
import IVPLitElementBase from './lit-element-base.js';

export default class DlgPopup extends IVPLitElementBase {

	static get tag() { return "popup-dialog" }

    static get properties() {
        return {            
            title:  { type: String },
            text:  { type: String },
            yes:   { type: String },
            no:   { type: String },
        };
    }

    constructor() {
        super();
        this.percent = 0
        this.title = "Title"
        this.text = "Text"
        this.yes = "Ok"
        this.no = "Annulla"
    }

    render() {
        return html`
        <!-- Modal -->
        <div class="modal fade" id="popup_dialog" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered " role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${this.title}</h5>
                        <!--button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button-->
                    </div>
                    <div class="modal-body">
                        <p>${this.text}</p>
                    </div>
                    <div class="modal-footer">
                            <button type="button" class="btn btn-primary" @click="${this.OnYes}">${this.yes}</button>
                            ${(this.no!=null)?html`<button type="button" class="btn btn-secondary" @click="${this.OnNo}">${this.no}</button>`:``}
                    </div>
                </div>
            </div>
        </div>
        `
    }
    
    firstUpdated(changedProps) {
        $('#popup_dialog').modal({backdrop:'static',keyboard:false})
    }

    updated() {        
    }

    static Show(title,text,yes,no) {

        let el = new DlgPopup()
        el.title = title
        el.text = text
        el.yes = yes
        if (el.yes == null)
            el.yes = "Ok"
        el.no = no

        el.promise = new Promise((resolve) => {
            el.promise_resolve = resolve
        })
        
        let vels = document.querySelectorAll("popup-dialog")
		if (vels != null)
			vels.forEach((el)=>{ el.remove() })

        document.body.append(el)
        return el.promise
    }

    Remove() {
        $('#popup_dialog').modal('hide')
        this.remove()
    }    

    OnYes() {
        this.promise_resolve(true)
        this.Remove()
    }

    OnNo() {
        this.promise_resolve(false)
        this.Remove()
    }
    
}
DlgPopup.RegisterElement()