import axios from 'axios'

const api_base_url    = "API_URL"
//const api_base_url = "https://api.23spv.online"

if (window.RUNTIME == 'dev') {
    console.log("#########            DEVELOPMENT MODE")
    console.log(`#########            API_BASE_URL: ${api_base_url}`)
}

export default class Api {

    static async DoIO(func, method, params, is_json) {
        let data = params
        let headers = { "Authorization": `Bearer ${Api.auth_token}` }

        headers["Content-Type"] =  'application/json'
        data = JSON.stringify(params)

        let res = await axios({
            "method": method,
            "url": `${api_base_url}${func}`,
            "data": data,
            "headers": headers
        })

        return res.data
    }

    static async Init(auth_token) {
        Api.auth_token = auth_token
        return this.DoIO("/api/auth","get")
    }

    static async Login(login, psw) {        
        let auth_token = await this.DoIO("/api/auth","post",{login:login,secret:psw})

        Api.auth_token = auth_token

        return auth_token
    }

    static async GetYards() {        
        return this.DoIO("/api/yards","get")
    }

    static async GetYard(id) {        
        return this.DoIO("/api/yards/"+id,"get")
    }

    static async SetYardOperator(yard_id,operator_id) {        
        return this.DoIO(`/api/yards_operator/${yard_id}/${operator_id}`,"post")
    }

    static async SaveYardProperties(yard_id,section,properties) {        
        return this.DoIO(`/api/yards_properties/${yard_id}/${section}`,"post",{properties: properties})
    }

    static async SetYardApprovationRequest(yard_id) {        
        return this.DoIO(`/api/yards_approvation/request/${yard_id}`,"post")
    }

    static async SetYardIntegrationsRequest(yard_id,integration_notes) {        
        return this.DoIO(`/api/yards_approvation/integrations/${yard_id}`,"post",{"integration_notes": integration_notes})
    }    
    
    static async SetYardApprove(yard_id,current_section,desired_section) {        
        return this.DoIO(`/api/yards_approvation/approve/${yard_id}/${current_section}`,"post",{"desired_section": desired_section})
    }    

    static async SetYardStatus(yard_id,status) {        
        return this.DoIO(`/api/yards_status/${yard_id}/${status}`,"post",)
    }    

    static async GetContractors() {        
        return this.DoIO("/api/contractors","get")
    }

    static async GetContractor(id) {        
        return this.DoIO("/api/contractors/"+id,"get")
    }

    static async GetContractorDocuments(id) {        
        return this.DoIO("/api/contractors_documents/"+id,"get")
    }

    static async SaveContractorDocuments(contractor_id,file) {        
        if (file.id == null)
            return this.DoIO("/api/contractors_documents/"+contractor_id,"put",file)
        else 
            return this.DoIO("/api/contractors_documents/"+contractor_id,"post",file)
    }

    static async DeleteContractorDocuments(contractor_id,id) {        
        return this.DoIO(`/api/contractors_documents/${contractor_id}/${id}`,"delete")
    }

    static async SaveContractor(data) {     
        if (parseInt(data.id)>0)   
            return this.DoIO("/api/contractors/"+data.id,"post",data)
        else
            return this.DoIO("/api/contractors","put",data)
    }    
    
    static async GetContractorPrivacy() {        
        return this.DoIO("/api/contractors_privacy","get")
    }    

    static async SetContractorPrivacy() {        
        return this.DoIO("/api/contractors_privacy","put")
    }       

    static async GetContractorAttachmentA(contractor_id) {        
        return this.DoIO("/api/contractors_attachment_a/"+contractor_id,"get")
    }        

    static async GetOperators() {        
        return this.DoIO("/api/operators","get")
    }   

    static async GetOperator(operator_id) {        
        return this.DoIO("/api/operators/"+operator_id,"get")
    }       

    static async SaveOperator(data) {     
        if (parseInt(data.id)>0)   
            return this.DoIO("/api/operators/"+data.id,"post",data)
        else
            return this.DoIO("/api/operators","put",data)
    }       
    
    static async UploadInit(file_name) {
        
        return this.DoIO("/api/auth/upload/"+encodeURI(file_name),"get")
    }

    static async GetYardTenants(yard_id) {        
        return this.DoIO("/api/yards_tenants/"+yard_id,"get")
    }    

    static async GetYardTenant(yard_id,tenant_id) {        
        return this.DoIO("/api/yards_tenants/"+yard_id+"/"+tenant_id,"get")
    } 
    
    static async DeleteYardTenant(yard_id,tenant_id) {        
        return this.DoIO("/api/yards_tenants/"+yard_id+"/"+tenant_id,"delete")
    } 

    static async SaveYardTenant(yard_id, data) {        
        if (data.id == null)
            return this.DoIO("/api/yards_tenants/"+yard_id,"put",data)
        else
            return this.DoIO("/api/yards_tenants/"+yard_id+"/"+data.id,"post",data)
    }    

    static async SaveYardTenantProperties(yard_id,tenant_id,properties) {        
        return this.DoIO(`/api/yards_tenants_properties/${yard_id}/${tenant_id}`,"post",{properties: properties})
    }    

    static async GetLogs() {        
        return this.DoIO("/api/calls_trace","get")
    }     
}