import { html  } from 'lit';
import IVPLitElementBase from './lit-element-base.js';
import Api from './api.js'
import Auth from '../auth.js';

export default class DlgPrivacy extends IVPLitElementBase {

	static get tag() { return "privacy-dialog" }

    static get properties() {
        return {
            //label: { type: String },
        };
    }

    constructor() {
        super();        
    }

    firstUpdated(changedProps) {
        $('#privacy_dialog').modal({backdrop:'static',keyboard:false})
    }

    static async EnsureAccepted() {

        let el = new DlgPrivacy()

        el.promise = new Promise((resolve) => {
            el.promise_resolve = resolve
        })
      
        document.body.append(el)
        return this.promise
    }

    async OnAccept() {
        await Api.SetContractorPrivacy()
        this.promise_resolve()
        $('#privacy_dialog').modal('hide')
        this.remove()
    }

 
    render() {
        return html`
        <div class="modal fade show" id="privacy_dialog" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalScrollableTitle" style="display: block;" aria-modal="true">
            <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Informativa Privacy</h5>
                        <!--button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button-->
                    </div>
                    <div class="modal-body">
                    <p><strong>INFORMATIVA REGOLAMENTO EUROPEO n. 2016/679</strong></p>
                    <p><strong>1. Titolare del trattamento</strong></p>
                    <p>Il Titolare del trattamento è VENTITRE’ S.P.V. S.R.L. (C.F./P.I. 03113200301), in persona dell’amministratore unico Giovanni Sanna, corrente in Tavagnacco (UD) 33010 via Alpe Adria n. 6, di seguito “il Titolare”.</p>
                    <p>Il Titolare intende fornire all’interessato l’informativa completa sulle finalità e sulle modalità del trattamento dei dati personali.</p>
                    <p><strong>2. Modalità del trattamento dei dati personali</strong></p>
                    <p>I dati personali (es. dati anagrafici, recapito telefonico, email, etc.) trasmessi sono trattati su supporti informatici o comunque con l’ausilio di strumenti informatizzati o automatizzati nel rispetto delle misure minime di sicurezza e, comunque, in modo da garantire l’integrità, la sicurezza e la riservatezza dei dati stessi.</p>
                    <p><strong>3. Finalità del trattamento</strong></p>
                    <p>Il Titolare tratterà i Suoi dati personali per le seguenti finalità:</p>
                    <ol>
                    <li><p>svolgimento delle attività contrattuali e precontrattuali</p>
                    </li>
                    <li><p>adempimenti ad obblighi fiscali o legali</p>
                    </li>
                    <li><p>invio di informazioni commerciali/promozionali;</p>
                    </li>
                    </ol>
                    <p>I dati personali, altresì, saranno trattati esclusivamente per finalità strettamente connesse e strumentali all’adempimento delle obbligazioni inerenti i predetti punti.</p>
                    <p>Il consenso da Lei manifestato è la base giuridica del trattamento ai sensi della normativa del GDPR.</p>
                    <p><strong>4. Natura del conferimento</strong></p>
                    <p>Il conferimento dei Suoi dati per le finalità di cui ai punti 1) e 2) dell’art.3 non richiede alcun consenso formale in quanto propedeutico ed essenziale a qualsiasi rapporto contrattuale o precontrattuale.</p>
                    <p>Il conferimento dei Suoi dati per le finalità di cui ai punti 3) dell&#39;art. 3 è facoltativo e richiede il Suo consenso espresso. Il mancato assenso implicherà esclusivamente le conseguenze di seguito descritte: l&#39;impossibilità di inviare informazioni commerciali e/o promozionali.</p>
                    <p>In ogni caso qualora Lei abbia prestato il consenso espresso per autorizzare il Titolale a perseguire le finalità di cui ai punti 3) dell&#39;art. 3, resterà comunque libero in ogni momento di revocarlo, inviando senza formalità alcuna una chiara comunicazione in tal senso all’indirizzo di posta elettronica <a href="&#x6d;&#97;&#105;&#108;&#x74;&#x6f;&#x3a;&#x69;&#110;&#102;&#111;&#x40;&#50;&#x33;&#x73;&#112;&#118;&#x2e;&#99;&#x6f;&#109;">&#x69;&#110;&#102;&#111;&#x40;&#50;&#x33;&#x73;&#112;&#118;&#x2e;&#99;&#x6f;&#109;</a>. A seguito della ricezione di tale richiesta di opt-out il Titolare procederà tempestivamente alla rimozione e cancellazione dei dati dai database utilizzati per il trattamento per finalità di marketing diretto e indiretto e per l’invio di newsletter e ad informare, per le medesime finalità di cancellazione, eventuali terzi cui i dati siano stati comunicati. La semplice ricezione della richiesta di cancellazione varrà automaticamente quale conferma di avvenuta cancellazione.</p>
                    <p><strong>5. Destinatari o categoria di destinatari dei dati personali</strong></p>
                    <p>I dati personali da Lei conferiti, per le finalità sopra descritte, potranno essere portati a conoscenza di dipendenti e/o collaboratori de il Titolare ed comunicati ai seguenti soggetti:</p>
                    <ol>
                    <li><p>società terze eventualmente incaricate dal Titolare di provvedere all’esecuzione delle obbligazioni assunte da quest’ultimo per l’attuazione dei trattamenti previsti dalle finalità di cui ai punti 1) e 2) dell’art.3;</p>
                    </li>
                    <li><p>tutti i soggetti (ivi incluse le Pubbliche Autorità) che hanno diritto di accesso ai dati in forza di provvedimenti normativi o amministrativi;</p>
                    </li>
                    <li><p>società terze che forniscono servizi di supporto essenziali ai trattamenti ed hanno accesso diretto o indiretto ai Suoi dati;</p>
                    </li>
                    <li><p>società terze incaricate dal Titolare per i trattamenti dei dati previsti dalle finalità di cui ai punti 3) dell’art.3;</p>
                    </li>
                    <li><p>Tutti i collaboratori o fornitori utilizzati dal Titolare per il trattamento dei Suoi dati personali sono stati opportunamente e legalmente autorizzati e responsabilizzati sulle modalità e le finalità dei trattamenti ad essi attribuiti ed agiranno nel rispetto ed in accordo alla presente informativa.</p>
                    </li>
                    </ol>
                    <p>I dati personali da Lei conferiti, per le finalità sopra descritte, potranno essere trasferiti a partner commerciali per i trattamenti dei dati previsti dalle finalità di cui ai punti 3) dell’art.3.</p>
                    <p>I dati personali da Lei conferiti e successivamente trattati in relazione alla gestione del servizio non sono soggetti a diffusione.</p>
                    <p>Per i soggetti indicati viene indicata solo la categoria dei destinatari, in quanto oggetto di frequenti aggiornamenti e revisioni. Pertanto, gli Interessati potranno richiedere l’elenco aggiornato dei destinatari contattando il Titolare del trattamento attraverso i canali indicati all’art. 1 della presente informativa.</p>
                    <p><strong>6. Tempi di conservazione dei dati</strong></p>
                    <p>I Suoi dati personali saranno conservati per i tempi definiti dalla normativa di riferimento, che di seguito si specificano ai sensi dell’art. 13, comma 2, lettera a) GDPR:</p>
                    <ol>
                    <li><p>per le finalità indicate ai punti 1), 2) dell’art.3 per i tempi prescritti dalle leggi vigenti e comunque per un periodo non inferiore ai 10 (dieci) anni</p>
                    </li>
                    <li><p>per le finalità indicate ai punti 3) dell’art. 3 per 3 (tre) anni dal momento del rilascio del consenso al trattamento;</p>
                    </li>
                    </ol>
                    <p><strong>7. Esercizio dei diritti da parte dell’interessato</strong></p>
                    <p>Ai sensi degli articoli 13, comma 2, lettere b) e d), 15, 18, 19 e 21 GDPR si informa l’interessato che ha diritto di:</p>
                    <ol>
                    <li><p>Accesso ai dati personali: ottenere la conferma o meno che sia in corso un trattamento di dati che La riguardano e, in tal caso, l’accesso alle seguenti informazioni: le finalità, le categorie di dati, i destinatari, il periodo di conservazione, il diritto di proporre reclamo ad un’autorità di controllo, il diritto di richiedere la rettifica o cancellazione o limitazione del trattamento od opposizione al trattamento stesso nonché l’esistenza di un processo decisionale automatizzato;</p>
                    </li>
                    <li><p>Richiesta di rettifica o cancellazione degli stessi o limitazione dei trattamenti che La riguardano; per “limitazione” si intende il contrassegno dei dati conservati con l’obiettivo di limitarne il trattamento in futuro;</p>
                    </li>
                    <li><p>Opposizione al trattamento: opporsi per motivi connessi alla Sua situazione particolare al trattamento di dati per l’esecuzione di un compito di interesse pubblico o per il perseguimento di un legittimo interesse de il Titolare;</p>
                    </li>
                    <li><p>Portabilità dei dati: nel caso di trattamento automatizzato svolto sulla base del consenso o in esecuzione di un contratto, di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico, i dati che La riguardano; in particolare, i dati Le verranno forniti da il Titolare in formato .xml;</p>
                    </li>
                    <li><p>Revoca del consenso al trattamento per finalità di marketing, sia diretto che indiretto, ricerche di mercato e profilazione; l&#39;esercizio di tale diritto non pregiudica in alcun modo la liceità dei trattamenti svolti prima della revoca;</p>
                    </li>
                    <li><p>Proporre reclamo ai sensi dell’art. 77 RGPD all’autorità di controllo competente in base alla Sua residenza abituale, al luogo di lavoro oppure al luogo di violazione dei Suoi diritti; per l’Italia è competente il Garante per la protezione dei dati personali, contattabile tramite i dati di contatto riportati sul sito web <a href="http://www.garanteprivacy.it">http://www.garanteprivacy.it</a>. I predetti diritti potranno essere esercitati inviando apposita richiesta al Titolare del trattamento mediante i canali di contatto indicati all’art. 1 della presente informativa.</p>
                    </li>
                    </ol>
                    <p>Le richieste relative all’esercizio dei Suoi diritti saranno evase senza ingiustificato ritardo e, in ogni modo, entro un mese dalla domanda; solo in casi di particolare complessità e del numero di richieste tale termine potrà essere prorogato di ulteriori 2 (due) mesi.</p>
                    <p>Si informa specificatamente e separatamente, come richiesto dall’art. 21 GDPR che qualora i dati personali siano trattati per finalità di marketing l’interessato ha diritto di opporsi in qualsiasi momento e che qualora l’interessato si opponga al trattamento, i dati personali non potranno più essere oggetto di trattamento per tali finalità.</p>
                    <p>L’esercizio dei diritti non è soggetto ad alcun vincolo di forma ed è gratuito. L’indirizzo di posta elettronica per l’esercizio dei diritti è <a href="&#109;&#x61;&#x69;&#108;&#x74;&#x6f;&#x3a;&#x69;&#x6e;&#x66;&#111;&#64;&#50;&#51;&#x73;&#x70;&#118;&#x2e;&#99;&#x6f;&#109;">&#x69;&#x6e;&#x66;&#111;&#64;&#50;&#51;&#x73;&#x70;&#118;&#x2e;&#99;&#x6f;&#109;</a>.</p>
                    <p><strong>Contatti:</strong></p>
                    <p>Sede Legale: Via Alpe Adria 6, 33010 Tavagnacco (UD)</p>
                    <p>Telefono: 0432-1692200</p>
                    <p>E-mail: info@23spv.com</p>
                    <p>PEC: ventitrespv@pec.it</p>
                                                            
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" @click="${this.OnAccept}">Accetta e procedi</button>
                    </div>
                </div>
            </div>
        </div>
        `
    }
}
DlgPrivacy.RegisterElement()