import Api from "./lib/api.js"


export default class Auth {

    static async Init() {

        try {
            let auth_token = localStorage.getItem("auth_token")
            let ret = await Api.Init(auth_token)
            if (ret.profile == null)
                throw "login"

            Auth.user = ret

            if (window.raygun_set_user != null)
                window.raygun_set_user(Auth.user.id,Auth.user.login)

            document.querySelector("body").classList.add(Auth.user.profile)
            document.querySelector(".USER").innerHTML = Auth.user.login
            document.querySelector(".LOGOUT").addEventListener("click",async ()=>{
                localStorage.setItem("auth_token","")
                
                if (window.raygun_set_user != null)
                    window.raygun_set_user(null)

                document.location.href = "./auth-login.html"    
            })

            return true
        }
        catch(Ex) {
            console.log(Ex)
            document.location.href = "./auth-login.html"
        }

        return false
    }

    static async Login(login, psw) {
        try {
            let auth = await Api.Login(login,psw)
            localStorage.setItem("auth_token",auth.token)

            return true
        }
        catch (Ex) {
            return false
        }
    }

}

window.Auth = Auth
